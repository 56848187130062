.about-me-styling{
        background-color: #f4f1f1;
        padding: 15px 20px;
        border-radius: 8px;
        box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.11);
        margin-bottom: 60px;
   
}
.pill-animation{
    background-color: #93e4c1;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.pill-animation:hover{
    transform: scale(1.1); 
}