button {
    background: none;      
    color: inherit;          
    border: none;          
    padding: 0;              
    font: inherit;           
    outline: inherit;        
    -webkit-appearance: none;  
    -moz-appearance: none;     
    appearance: none;          
}

.navbar-link {
    color: black; 
    text-decoration: none; 
    transition: color 0.3s ease;
}
.navbar-link-resume{
    color: black; 
    text-decoration: none; 
}
.resume-button {
    border: 1px solid black;
    border-radius: 30px;
    padding: 0px 10px;
    transition: border-color 0.3s ease, color 0.3s ease; 
}

.navbar-link:hover, 
.navbar-link:focus {
    color: #118a7e;
    outline: none;
}
.navbar-link-resume:focus, 
.navbar-link-resume:hover {
    outline: none; 
}

.navbar-link-resume:hover .resume-button, 
.navbar-link-resume:focus .resume-button {
    border-color: #118a7e; 
    color: #118a7e;
}


.navbar-scrolled .navbar-link:hover, 
.navbar-scrolled .navbar-link:focus {
    color: white;
    outline: none;
}

.navbar-scrolled .navbar-link-resume:hover, 
.navbar-scrolled .navbar-link-resume:focus {
    color: white;
    outline: none;
}

.navbar-scrolled .navbar-link-resume:hover .resume-button, 
.navbar-scrolled .navbar-link-resume:focus .resume-button {
    border-color: white; 
    color: white;
}
