.bottomBanner-style {
    width: 100%;
    background-color: #118a7e; /* Change as per your color scheme */
    color: #303841;
    padding: 30px 0;
    
}
@media (max-width: 992px) {
    .bottomBanner-style{
        padding: 30px 10px;
    }
  }
.banner-alignment{
    margin: 0 auto;
    max-width: 60rem;
}
.icon-style {
    margin-right: 5px;
}
.custom-link-bottomB {
    color: white; 
    transition: color 0.3s ease;
    display: inline-block;
  }
  
  .custom-link-bottomB:hover, .custom-link-bottomB:focus {
    outline: none;
    color: #303841;
    text-decoration: none;
  }
