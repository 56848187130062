.banner{
  height: 50vh;
}

.title{
    color: #3baea0;
    font-size: 95px;
}
@media (max-width: 576px) {
  .title {
      font-size: 60px;
  }
  .banner-items{
   margin: 25px 0;
  }

}
@media (max-width: 390px) {
  .title{
    margin-top: 20px;
    font-size: 40px;
  }
  .banner-items{
    margin: 40px 0;
  }
  .banner-text{
    font-size: 20px;
  }
  
}
@media (min-width: 576px) {
  .banner {
    height: 100vh;
  }
}
.custom-link {
  color: #303841; 
  transition: transform 0.3s ease, opacity 0.3s ease;
  display: inline-block;
}

.custom-link:hover, .custom-link:focus {
  outline: none;
  opacity: 0.7;
  transform: scale(1.2);
  text-decoration: none;
}
.cursor {
  display: inline-block;
  width: 8px; 
  height: 1em;
  border-radius: 4px; 
  background-color: #303841; 
  margin-left: 10px;
  vertical-align: middle; 
  animation: blink 1s step-start infinite;
}
@keyframes blink {
  50% { opacity: 0; }
}


