.card-container {
    background-color: #f4f1f1;
    position: relative;
    padding: 15px 25px;
    border-radius: 12px;
    box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.11);

    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-container-action::before{
    content: ''; 
    position: absolute; 
    top: 0;
    left: 0; 
    right: 0; 
    bottom: 0; 
    z-index: 1; 
}

.card-container:hover,.card-container:focus {
    box-shadow: 0 0 0 8px #3baea0;
    transform: translateY(-5px); 
    outline: none;
}


.image-size {
    width: 85px;
    height: 85px; 
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    border: 2px solid black;
    transition: transform 0.3s ease;
}
.pill-design{

    background-color: #3baea0;
    border-radius: 16px;
    padding: 4px 8px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

}

@media (max-width: 768px) {
    .image-size{
        width: 60px;
        height: 60px;
    }

   
  }